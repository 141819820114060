<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Syllabus Quiz</h2>

      <p class="mb-n3">
        a) It is allowable to drop the lab portion of General Chemistry I or II (CHEM 115 or CHEM
        116) and stay enrolled in the lecture portion (CHEM 105 or CHEM 106) if you want or need to.
        You can always take the lab later.
      </p>

      <v-radio-group v-model="inputs.takeLabLater" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsTF"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) It is allowable for you to drop the lecture part of the class and keep taking the lab if
        dropping the lab would then take you below full-time student status.
      </p>

      <v-radio-group
        v-model="inputs.dropLecturePartTime"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsTF"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        c) Failure to be wearing long pants, closed-toe shoes, and goggles when in the laboratory
        will most likely result in you being asked to leave for the day and to take a zero for the
        day's assignments due to safety violations.
      </p>

      <v-radio-group v-model="inputs.missingPPE" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsTF"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">d) What kind of goggles are you required to wear while in the laboratory?</p>

      <v-radio-group v-model="inputs.requiredGlasses" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsGlasses"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        e) A numerical grade of
        <number-value :value="numericalGrade" />
        is equivalent to what letter grade in the General Chemistry lab?
      </p>

      <v-radio-group
        v-model="inputs.numGradeToLetterGrade"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsLetterGrade"
          :key="'pt-5-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        f) Several experiments will require you to work with an unknown chemical that will have a
        code written on the container. The code must be entered on the post-lab assignment pages
        that you submit to your TA in order for the TA to know how to grade your paper. If you do
        not include the unknown code on your assignment, what is the consequence?
      </p>

      <v-radio-group
        v-model="inputs.forgettingUnknownConsequences"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsForgotUnknown"
          :key="'pt-6-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        g) Your lab practical (i.e. FINAL EXAM) grade will be worth what percentage of your final
        average in this course?
      </p>

      <v-radio-group v-model="inputs.weightOfPractical" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsPracticalWeights"
          :key="'pt-7-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>

    <p class="mb-n3">
      h) If you have a question about the grading of one of your returned papers, you must ask your
      TA that question BY the day after you receive the graded paper. After that, the grade will not
      be changed.
    </p>

    <v-radio-group v-model="inputs.gradeChange" class="ml-6 mb-0" :disabled="!allowEditing">
      <v-radio
        v-for="option in optionsTF"
        :key="'pt-8-' + option.value"
        class="my-1"
        :value="option.value"
      >
        <template #label>
          <stemble-latex :content="option.text" />
        </template>
      </v-radio>
    </v-radio-group>

    <p class="mb-n3">
      i) Being disruptive in class, sitting on the benches, arguing, texting, making phone calls, or
      otherwise NOT BEING ON TASK will most likely result in you being asked to leave the lab for
      the day and taking a zero for the day's work for disruptive behavior.
    </p>

    <v-radio-group v-model="inputs.disruptiveBehaviour" class="ml-6 mb-0" :disabled="!allowEditing">
      <v-radio
        v-for="option in optionsTF"
        :key="'pt-9-' + option.value"
        class="my-1"
        :value="option.value"
      >
        <template #label>
          <stemble-latex :content="option.text" />
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue';

export default {
  name: 'OleMissSyllabusQuiz',
  components: {NumberValue, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        takeLabLater: null,
        dropLecturePartTime: null,
        missingPPE: null,
        requiredGlasses: null,
        numGradeToLetterGrade: null,
        forgettingUnknownConsequences: null,
        weightOfPractical: null,
        gradeChange: null,
        disruptiveBehaviour: null,
      },
      optionsTF: [
        {text: 'True', value: 'true'},
        {text: 'False', value: 'false'},
      ],
      optionsGlasses: [
        {text: 'Full face shield', value: 'faceShield'},
        {text: 'Green', value: 'green'},
        {text: 'Indirectly- or non-vented', value: 'indirectNonVented'},
        {text: 'Directly vented', value: 'vented'},
        {text: 'Safety glasses', value: 'safetyGlasses'},
      ],
      optionsLetterGrade: [
        {text: 'A', value: 'A'},
        {text: 'B', value: 'B'},
        {text: 'C', value: 'C'},
        {text: 'D', value: 'D'},
        {text: 'F', value: 'F'},
      ],
      optionsForgotUnknown: [
        {text: 'Automatic grade of zero on the entire assignment', value: 'zero'},
        {
          text: 'Grade of zero UNTIL the code can be written on the paper and the paper graded',
          value: 'zeroUntilProvided',
        },
        {
          text: 'The TA will assume that the code is the one that makes the most sense according to your data',
          value: 'TApicks',
        },
        {
          text: 'The TA will hold the paper until you get back to lab, then ask you what your code was',
          value: 'ungradedUntilProvided',
        },
      ],
      optionsPracticalWeights: [
        {text: '$\\text{10}\\%$', value: '10'},
        {text: '$\\text{15}\\%$', value: '15'},
        {text: '$\\text{20}\\%$', value: '20'},
        {text: '$\\text{25}\\%$', value: '25'},
      ],
    };
  },
  computed: {
    numericalGrade() {
      return this.taskState.getValueBySymbol('numericalGrade').content;
    },
  },
};
</script>
<style scoped></style>
